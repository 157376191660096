import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';

const Tutorials = () => {

  useEffect(() => {
    // Any necessary effects can be added here
  }, []);

  return (
    <div>
      <Header />

      <section className="tutorial-section">
        <div className="container">
          <div className="section-head col-sm-12">
            <h4><span>Our Tutorials</span></h4>
          </div>
          <div className="text-center">

            <p className="section-description">
              Watch our video tutorials for quick and easy learning. Whether you're a beginner or an advanced user, our tutorials will guide you through the various features of N D Connect.
            </p>
          </div>

          <div className="row g-4">
            <div className="col-md-6 col-lg-4">
              <div className="video-card border rounded shadow-sm d-flex flex-column">
                <div className="video-thumbnail position-relative">
                  <iframe
                    className="rounded"
                    width="100%"
                    height="200"
                    src="https://www.youtube.com/embed/VIDEO_ID_1"
                    title="Introduction to Our Platform"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="play-overlay position-absolute top-50 start-50 translate-middle">
                    <i className="bi bi-play-circle-fill"></i>
                  </div>
                </div>
                <div className="video-info p-3">
                  <h5 className="video-title">Introduction to Our Platform</h5>
                  <p className="video-description">Learn the basics of getting started with our platform.</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="video-card border rounded shadow-sm d-flex flex-column">
                <div className="video-thumbnail position-relative">
                  <iframe
                    className="rounded"
                    width="100%"
                    height="200"
                    src="https://www.youtube.com/embed/VIDEO_ID_2"
                    title="Advanced Features Tutorial"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="play-overlay position-absolute top-50 start-50 translate-middle">
                    <i className="bi bi-play-circle-fill"></i>
                  </div>
                </div>
                <div className="video-info p-3">
                  <h5 className="video-title">Advanced Features Tutorial</h5>
                  <p className="video-description">Discover the advanced features that can enhance your workflow.</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="video-card border rounded shadow-sm d-flex flex-column">
                <div className="video-thumbnail position-relative">
                  <iframe
                    className="rounded"
                    width="100%"
                    height="200"
                    src="https://www.youtube.com/embed/VIDEO_ID_3"
                    title="Tips & Tricks"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="play-overlay position-absolute top-50 start-50 translate-middle">
                    <i className="bi bi-play-circle-fill"></i>
                  </div>
                </div>
                <div className="video-info p-3">
                  <h5 className="video-title">Tips & Tricks</h5>
                  <p className="video-description">Get tips and tricks to maximize your efficiency.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Tutorials;
