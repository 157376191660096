import React, { useState } from 'react';
import './Customersupport.css';
import Header from './Header';
import Footer from './Footer';

const Customersupport = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data submitted:', formData);
    };

    return (
        <>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <section className="customer-support-section">
                            <div className="container">
                                <h1>Customer Support</h1>

                                <div className="support-details">
                                    <div className="support-form">
                                        <h2>Submit a Request</h2>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="name">Name:</label>
                                                <input
                                                    type="text"
                                                    id="name"

                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email:</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required

                                                    style={{ width: "100%", textAlign: "left" }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="message">Message:</label>
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                    style={{ width: "100%", textAlign: "left" }}
                                                />
                                            </div>
                                            <button type="submit" className="submit-btn">
                                                Submit
                                            </button>
                                        </form>
                                    </div>

                                    <div className="support-info">
                                        <h2>Contact Us</h2>
                                        <p>For immediate assistance, please contact us:</p>
                                        <p>Email: info@ndtechland.com</p>
                                        <p>Phone: 0120 4354103</p>
                                        <p>Hours: Monday to Satursday, 9:30 AM - 6:30 PM</p>
                                    </div>
                                </div>

                                <div className="faq-section">
                                    <h2>Frequently Asked Questions</h2>
                                    <div className="faq-item">
                                        <h3>How do I reset my password?</h3>
                                        <p>You can reset your password by clicking the "Forgot Password" link on the login page.</p>
                                    </div>
                                    <div className="faq-item">
                                        <h3>How can I track my order?</h3>
                                        <p>You can track your order through the "Order History" section of your account.</p>
                                    </div>
                                    <div className="faq-item">
                                        <h3>What is the return policy?</h3>
                                        <p>You can return your product within 30 days of purchase for a full refund.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Customersupport;
