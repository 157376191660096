import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openSubMenus, setOpenSubMenus] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is logged in by checking the token in localStorage
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleToggleNav = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleToggleSubMenu = (menu) => {
        if (openSubMenus.includes(menu)) {
            setOpenSubMenus(openSubMenus.filter(item => item !== menu));
        } else {
            setOpenSubMenus([...openSubMenus, menu]);
        }
    };

    const handleLogout = () => {
        // Clear the token from localStorage and redirect to login page
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        navigate('/Userlogin');
    };




    return (
        <header className="overlay-black">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="menu">
                            <div className="brand-logo">
                                <Link to="/">
                                    <img
                                        src="../assets/images/icon/logo01.png"
                                        alt="Brand Logo"
                                        className="img-fluid lazyload"
                                        style={{ height: 66, width: 198 }}
                                    />
                                </Link>
                            </div>
                            <nav>
                                <div className="main-navbar">
                                    <div id="mainnav">
                                        <div className="toggle-nav" onClick={handleToggleNav}>
                                            <i className="fa fa-bars sidebar-bar" />
                                        </div>
                                        <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`} onClick={handleToggleNav} />
                                        <ul className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                                            <li className="back-btn">
                                                <div className="mobile-back text-end" onClick={handleToggleNav}>
                                                    <span>Back</span>
                                                    <i aria-hidden="true" className="fa fa-angle-right ps-2" />
                                                </div>
                                            </li>
                                            <li className="dropdown">
                                                <Link to="/" className="nav-link menu-title">
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="dropdown">
                                                <Link
                                                    to="#"
                                                    className="nav-link menu-title"
                                                    onClick={() => handleToggleSubMenu('about-us')}
                                                >
                                                    About Us
                                                    <i className={`fa ${openSubMenus.includes('about-us') ? 'fa-angle-up' : 'fa-angle-down'} ms-2`} />
                                                </Link>

                                                <ul className={`nav-submenu menu-content ${openSubMenus.includes('about-us') ? 'show' : ''}`}>
                                                    <li>
                                                        <Link to="/Ourstory">Our Story</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Missionvission">Mission & Vision</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Corevalues">Our Core Values</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Expertise">Our Expertise</Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="dropdown">
                                                <Link
                                                    to="#"
                                                    className="nav-link menu-title"
                                                    onClick={() => handleToggleSubMenu('products-services')}
                                                >
                                                    Products & Services
                                                    <i className={`fa ${openSubMenus.includes('products-services') ? 'fa-angle-up' : 'fa-angle-down'} ms-2`} />
                                                </Link>
                                                <ul className={`nav-submenu menu-content ${openSubMenus.includes('products-services') ? 'show' : ''}`}>
                                                    <li>
                                                        <Link to="/Overview">Overview</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Featurebenifits">Features & Benefits</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Pricingplan">Pricing Plans</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Requestdemo">Request a Demo</Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="dropdown">
                                                <Link
                                                    to="#"
                                                    className="nav-link menu-title"
                                                    onClick={() => handleToggleSubMenu('resources')}
                                                >
                                                    Resources
                                                    <i className={`fa ${openSubMenus.includes('resources') ? 'fa-angle-up' : 'fa-angle-down'} ms-2`} />
                                                </Link>
                                                <ul className={`nav-submenu menu-content ${openSubMenus.includes('resources') ? 'show' : ''}`}>
                                                    <li>
                                                        <Link to="/Blog">Blog</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Casestudy">Case Studies</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Whitepaper">Whitepapers</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Tutorials">Tutorials</Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="dropdown">
                                                <Link to="/Otherservices" className="nav-link menu-title">
                                                    Other Services
                                                </Link>
                                            </li>
                                            <li className="mega-menu">
                                                <Link to="/Contactus" className="nav-link menu-title">
                                                    Contact Us
                                                </Link>
                                            </li>


                                            {isLoggedIn ? (
                                                <li className="dropdown user-dropdown">
                                                    <div className="user-icon">
                                                        <i className="fas fa-user-circle" style={{ fontSize: '24px' }}></i>
                                                    </div>
                                                    <ul className="user-menu">

                                                    <li onClick={handleLogout}>
                                                            <Link to="/Dashboards">Dashboard</Link>
                                                        </li>

                                                        <li onClick={handleLogout}>
                                                            <Link to="#">Logout</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            ) : (
                                                <li className="dropdown">
                                                    <Link to="/Userlogin" className="nav-link menu-title">
                                                        <i className="fas fa-user"></i>
                                                    </Link>
                                                </li>
                                            )}

                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
