import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';

const Pricingplan = () => {

    useEffect(() => {
        // Add any necessary effect logic here
    }, []);

    // Dummy image URL
    const dummyImage = "https://via.placeholder.com/500x300";

    return (
        <div>
            <Header />
            <section className="pricing-section">
                <div className='container'>
                    <div className="section-head text-center">
                        <h4><span>Pricing Plans</span></h4>
                        <h3>N D Connect Pricing Plans</h3>
                        <p>
                            N D Connect offers a range of flexible pricing plans designed to fit businesses of all sizes and budgets. Whether you’re just starting out or managing a large enterprise, we have a plan that meets your needs:
                        </p>
                    </div>

                    <div className="row text-center">
                        {/* Basic Plan */}
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="card h-100">
                                <img src={dummyImage} alt="Basic Plan" className="card-img-top" />
                                <div className="card-body">
                                    <h4 className="card-title">Basic Plan</h4>
                                    <p className="card-text">
                                        Perfect for small businesses and startups, the Basic Plan includes essential features like customer management, invoicing, and employee management.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Standard Plan */}
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="card h-100">
                                <img src={dummyImage} alt="Standard Plan" className="card-img-top" />
                                <div className="card-body">
                                    <h4 className="card-title">Standard Plan</h4>
                                    <p className="card-text">
                                        The Standard Plan offers additional features like AMC reminders, salary slip generation, and real-time analytics. It’s ideal for growing businesses.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Premium Plan */}
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="card h-100">
                                <img src={dummyImage} alt="Premium Plan" className="card-img-top" />
                                <div className="card-body">
                                    <h4 className="card-title">Premium Plan</h4>
                                    <p className="card-text">
                                        Our most comprehensive offering, the Premium Plan includes all features of N D Connect, including advanced customization options and workflow automation.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Enterprise Plan */}
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="card h-100">
                                <img src={dummyImage} alt="Enterprise Plan" className="card-img-top" />
                                <div className="card-body">
                                    <h4 className="card-title">Enterprise Plan</h4>
                                    <p className="card-text">
                                        For businesses with unique requirements, we offer an Enterprise Plan that can be fully customized to meet your specific needs. Contact our sales team for a tailored quote.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-foot text-center">
                        <p>
                            All our plans come with 24/7 customer support and regular software updates. We also offer a free trial period for new customers, allowing you to experience the benefits of N D Connect before committing to a subscription.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Pricingplan;
