import React from 'react';
import Header from './Header';
import Footer from './Footer';
import AI from './images/AI.jpeg'
import Cloud from './images/Cloud.jpeg'
import Cybersecurity from './images/Cybersecurity.jpeg'
import bigdata from './images/bidata.jpeg'
const Whitepaper = () => {

  return (
    <>
      <Header />

      <section className="whitepaper-section ">
        <div className="container">
          <div className="section-head col-sm-12">
            <h4><span>Our Whitepapers</span></h4>
          </div>
          <div className="text-center">

            <h3 className="section-subtitle">Technology Whitepapers</h3>
            <p className="section-description">
              Gain insights into industry trends and innovations with our expertly researched whitepapers. Learn about the challenges businesses face and how N D Connect can provide solutions.
            </p>
          </div>

          <div className="row g-4">
            <div className="col-md-6 col-lg-4">
              <div className="whitepaper-card border rounded shadow-sm p-3 d-flex flex-column align-items-center">
                <div className="whitepaper-thumbnail mb-3">
                  <img src={AI}
                  
                  alt="AI & Machine Learning" className="img-fluid rounded" />
                </div>
                <h5 className="card-title text-center">AI & Machine Learning</h5>
                <p className="card-text text-center">
                  Discover how artificial intelligence and machine learning are transforming industries. Our whitepapers provide valuable insights into the applications of AI, challenges, and future trends in this rapidly evolving field.
                </p>
                <a href="#" className="btn btn-primary mt-auto">Download</a>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="whitepaper-card border rounded shadow-sm p-3 d-flex flex-column align-items-center">
                <div className="whitepaper-thumbnail mb-3">
                  <img src={Cloud}
                  
                  alt="Cloud Computing" className="img-fluid rounded" />
                </div>
                <h5 className="card-title text-center">Cloud Computing</h5>
                <p className="card-text text-center">
                  Understand the impact of cloud computing on modern businesses. Our whitepapers cover cloud adoption strategies, benefits, security considerations, and the latest advancements in cloud technology.
                </p>
                <a href="#" className="btn btn-primary mt-auto">Download</a>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="whitepaper-card border rounded shadow-sm p-3 d-flex flex-column align-items-center">
                <div className="whitepaper-thumbnail mb-3">
                  <img src={Cybersecurity}
                  
                  alt="Cybersecurity" className="img-fluid rounded" />
                </div>
                <h5 className="card-title text-center">Cybersecurity</h5>
                <p className="card-text text-center">
                  Stay informed about the latest cybersecurity threats and solutions. Our whitepapers offer guidance on protecting digital assets, understanding cyber threats, and implementing effective security measures.
                </p>
                <a href="#" className="btn btn-primary mt-auto">Download</a>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="whitepaper-card border rounded shadow-sm p-3 d-flex flex-column align-items-center">
                <div className="whitepaper-thumbnail mb-3">
                  <img src={bigdata}
                  
                  alt="Big Data & Analytics" className="img-fluid rounded" />
                </div>
                <h5 className="card-title text-center">Big Data & Analytics</h5>
                <p className="card-text text-center">
                  Learn about the role of big data and analytics in decision-making. Our whitepapers explore data management techniques, analytics tools, and the benefits of leveraging big data for strategic advantage.
                </p>
                <a href="#" className="btn btn-primary mt-auto">Download</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Whitepaper;
