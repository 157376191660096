import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import './Dashboard.css';

const Profile = () => {
    const [loading, setLoading] = useState(true);
    const [employeeData, setEmployeeData] = useState(null);
    const token = localStorage.getItem('token');
    const [formData, setFormData] = useState({
        fullName: '',
        personalEmailAddress: '',
        mobileNumber: '',
        fatherName: '',
        dateOfBirth: '',
        companyName: '',
        workEmail: '',
        dateOfJoining: '',
        shiftType: '',
        stateName: '',
        cityName: '',
        address1: '',
        address2: '',
        pincode: '',
        companyLocationName: '',
        panNumber: '',
        aadhaarNumber: '',
        Aadhar1: '',
        Aadhar2: '',
        pan: null,
        aadhar: null,
    });

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const isoFormat = /^\d{4}-\d{2}-\d{2}$/;
        if (isoFormat.test(dateString)) return dateString;

        const parts = dateString.split('/');
        if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            console.error(`Invalid date string provided: ${dateString}`);
            return '';
        }

        return date.toISOString().split('T')[0];
    };

    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await axios.get('https://api.ndtechland.com/api/EmployeeApi/GetEmployeeBasicInfo', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("API Response:", response.data);
                if (response.data && response.data.succeeded) {
                    const employeeData = response.data.data;
                    if (employeeData) {
                        setEmployeeData(employeeData);
                        setFormData({
                            fullName: employeeData.fullName || '',
                            personalEmailAddress: employeeData.personalEmailAddress || '',
                            mobileNumber: employeeData.mobileNumber || '',
                            fatherName: employeeData.fatherName || '',
                            dateOfBirth: formatDate(employeeData.dateOfBirth),
                            companyName: employeeData.companyName || '',
                            workEmail: employeeData.workEmail || '',
                            dateOfJoining: formatDate(employeeData.dateOfJoining),
                            shiftType: employeeData.shiftType || '',
                            stateName: employeeData.stateName || '',
                            cityName: employeeData.cityName || '',
                            address1: employeeData.address1 || '',
                            address2: employeeData.address2 || '',
                            pincode: employeeData.pincode || '',
                            companyLocationName: employeeData.companyLocationName || '',
                            panNumber: employeeData.panNo || '',
                            aadhaarNumber: employeeData.aadharNo || '',
                        });
                    } else {
                        console.error("Employee data is null or undefined");
                    }
                } else {
                    console.error("Failed to fetch employee data:", response.data.message);
                    alert(response.data.message || 'Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
                alert('An error occurred while fetching employee data');
            } finally {
                setLoading(false);
            }
        };

        fetchEmployeeData();
    }, [token]);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]); 


    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get('https://api.ndtechland.com/api/EmployeeApi/Getstate');
                if (response.data && response.data.data) {
                    setStates(response.data.data); 
                } else {
                    console.error('Error fetching states:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates();
    }, []);


    useEffect(() => {
        const fetchCities = async (stateId) => {
            try {
                const response = await axios.get(`https://api.ndtechland.com/api/EmployeeApi/getcity?stateid=${stateId}`);
                if (response.data && response.data.data) {
                    setCities(response.data.data); 
                } else {
                    console.error('Error fetching cities:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };


        if (formData.stateName) {
            const selectedState = states.find(state => state.sName === formData.stateName);
            if (selectedState) {
                fetchCities(selectedState.id);
            }
        }
    }, [formData.stateName, states]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;

        if (id === 'stateName') {
            setFormData({
                ...formData,
                stateName: value,
                cityName: ''  
            });
            setCities([]);  
        } else {
            setFormData({
                ...formData,
                [id]: value
            });
        }
    };

    const handleFileChange = (e) => {
        const { id, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: files[0], 
        }));
    };



    const submitPersonalDetails = async (event) => {
        event.preventDefault();

        const data = new FormData();

        const selectedState = states.find(state => state.sName === formData.stateName);
        const selectedCity = cities.find(city => city.city1 === formData.cityName);

        data.append('FullName', formData.fullName || '');
        data.append('MobileNumber', formData.mobileNumber || '');
        data.append('DateOfBirth', formData.dateOfBirth || '');

        if (selectedState) data.append('Stateid', selectedState.id);
        if (selectedCity) data.append('Cityid', selectedCity.id);

        data.append('Address1', formData.address1 || '');
        data.append('Address2', formData.address2 || '');
        data.append('Pincode', formData.pincode || '');
        data.append('PersonalEmailAddress', formData.personalEmailAddress || '');
        data.append('FatherName', formData.fatherName || '');
        data.append('panNo', formData.panNumber || '');
        data.append('aadharNo', formData.aadhaarNumber || '');

    
        if (formData.Empprofile) data.append('Empprofile', formData.Empprofile);
        if (formData.Aadhar1) data.append('Aadhar1', formData.Aadhar1);
        if (formData.PanbaseImage) data.append('PanbaseImage', formData.PanbaseImage);
        if (formData.Aadhar2) data.append('Aadhar2', formData.Aadhar2);

        try {
            const response = await axios.post('https://api.ndtechland.com/api/EmployeeApi/WebEmployeePersonalDetail', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.response.status) {

                Swal.fire({
                    title: 'Success!',
                    text: 'Profile updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    setFormData({
                        fullName: '',
                        personalEmailAddress: '',
                        mobileNumber: '',
                        fatherName: '',
                        dateOfBirth: '',
                        stateName: '',
                        cityName: '',
                        address1: '',
                        address2: '',
                        pincode: '',
                        panNumber: '',
                        aadhaarNumber: '',
                        Empprofile: '',
                        Aadhar1: '',
                        PanbaseImage: '',
                        Aadhar2: ''
                    });

                    setCities([]);

                    window.location.reload();
                });
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred while updating the profile';
            Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
    };



    if (loading) {
        return <div>Loading...</div>;
    }


    return (
        <div>
            {employeeData ? (
                <div>
                    <div className="profile-section">
                        <div className="dashboard-box profile-box">
                            <div className="dashboard-title d-flex justify-content-between align-items-center">
                                <h4 className="mb-0">Profile</h4>
                                <span className="edit-btn" data-bs-toggle="modal" data-bs-target="#edit-profile">
                                    <i className="fa fa-edit"></i> Edit
                                </span>
                            </div>
                            <div className="dashboard-detail">
                                <ul className="list-unstyled">
                                    {[
                                        { label: 'Name', value: employeeData.fullName },
                                        { label: 'Personal Email ID', value: employeeData.personalEmailAddress },
                                        { label: 'Phone No', value: employeeData.mobileNumber },
                                        { label: 'Date of Birth', value: employeeData.dateOfBirth },
                                        { label: "Father's Name", value: employeeData.fatherName },
                                        { label: 'Company Name', value: employeeData.companyName },
                                        { label: 'Work Email', value: employeeData.workEmail },
                                        { label: 'Joining Date', value: employeeData.dateOfJoining },
                                        { label: 'Current Shift', value: employeeData.shiftType },
                                        { label: 'State', value: employeeData.stateName },
                                        { label: 'City', value: employeeData.cityName },
                                        { label: 'Current Address', value: employeeData.address1 },
                                        { label: 'Permanent Address', value: employeeData.address2 },
                                        { label: 'Pin Code', value: employeeData.pincode },
                                        { label: 'Company Location', value: employeeData.companyLocationName },
                                        { label: 'PAN Number', value: employeeData.panNo },
                                        { label: 'Aadhaar Number', value: employeeData.aadharNo },

                                    ].map((item, index) => (
                                        <li className="detail-item" key={index}>
                                            <div className="details d-flex justify-content-between">
                                                <h6>{item.label}</h6>
                                                <h6>{item.value}</h6>
                                            </div>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>

                        <div className="modal fade" id="edit-profile" tabIndex="-1" aria-labelledby="editProfileModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="editProfileModalLabel">Edit Profile</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={submitPersonalDetails}>
                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="fullName" className="form-label">Full Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fullName"
                                                        value={formData.fullName || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="personalEmailAddress" className="form-label">Personal Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="personalEmailAddress"
                                                        value={formData.personalEmailAddress || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="mobileNumber" className="form-label">Phone Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="mobileNumber"
                                                        value={formData.mobileNumber || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="fatherName" className="form-label">Father's Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fatherName"
                                                        value={formData.fatherName || ''}
                                                        onChange={handleInputChange}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="dateOfBirth" className="form-label">Date of Birth</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="dateOfBirth"
                                                        value={formData.dateOfBirth || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        style={{ width: '100%' }}
                                                    />

                                                   

                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="companyName" className="form-label">Company Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="companyName"
                                                        value={formData.companyName || ''}
                                                        onChange={handleInputChange}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="workEmail" className="form-label">Work Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="workEmail"
                                                        value={formData.workEmail || ''}
                                                        onChange={handleInputChange}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="dateOfJoining" className="form-label">Date of Joining</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="dateOfJoining"
                                                        value={formData.dateOfJoining || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="shiftType" className="form-label">Shift Type</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="shiftType"
                                                        value={formData.shiftType || ''}
                                                        onChange={handleInputChange}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="stateName" className="form-label" style={{ textAlign: "left" }}>State</label>
                                                    <select
                                                        id="stateName"
                                                        className="form-select"
                                                        value={formData.stateName || ''}
                                                        onChange={handleInputChange}
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        <option value="" disabled>Select a state</option>
                                                        {states.map((state) => (
                                                            <option key={state.id} value={state.sName}>
                                                                {state.sName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>



                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="cityName" className="form-label">City</label>
                                                    <select
                                                        id="cityName"
                                                        className="form-select"
                                                        value={formData.cityName || ''}
                                                        onChange={handleInputChange}
                                                        style={{ textAlign: "left" }}
                                                        disabled={!cities.length}  
                                                    >
                                                        <option value="" disabled>Select a city</option>
                                                        {cities.map((city) => (
                                                            <option key={city.id} value={city.city1}>
                                                                {city.city1}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="address1" className="form-label">Current Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="address1"
                                                        value={formData.address1 || ''}
                                                        onChange={handleInputChange}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="address2" className="form-label">Permanent Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="address2"
                                                        value={formData.address2 || ''}
                                                        onChange={handleInputChange}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="pincode" className="form-label">Pin Code</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="pincode"
                                                        value={formData.pincode || ''}
                                                        onChange={handleInputChange}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="companyLocationName" className="form-label">Company Location</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="companyLocationName"
                                                        value={formData.companyLocationName || ''}
                                                        onChange={handleInputChange}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>

                                                <div className="col-lg-6">
                                                    <label htmlFor="Aadhar2" className="form-label">Upload Aadhar (Back)</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="Aadhar2"
                                                        onChange={handleFileChange}
                                                        accept=".pdf, .jpg, .jpeg, .png"
                                                    />
                                                    {formData.Aadhar2 && (
                                                        <small className="form-text">Uploaded: {formData.Aadhar2.name}</small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="panNumber" className="form-label">PAN Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="panNumber"
                                                        value={formData.panNumber || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="PanbaseImage" className="form-label">Upload PAN</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="PanbaseImage"
                                                        onChange={handleFileChange}
                                                        accept=".pdf, .jpg, .jpeg, .png"
                                                    />
                                                    {formData.PanbaseImage && (
                                                        <small className="form-text">Uploaded: {formData.PanbaseImage.name}</small>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="row mb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="aadhaarNumber" className="form-label">Aadhar Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="aadhaarNumber"
                                                        value={formData.aadhaarNumber || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="Aadhar1" className="form-label">Upload Aadhar (Front)</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="Aadhar1"
                                                        onChange={handleFileChange}
                                                        accept=".pdf, .jpg, .jpeg, .png"
                                                    />
                                                    {formData.Aadhar1 && (
                                                        <small className="form-text">Uploaded: {formData.Aadhar1.name}</small>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Update</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>No employee data available.</div>
            )}
        </div>
    );
};

export default Profile;
