import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Ourstory.css'; // Ensure this file has the updated styles
import con1 from './images/con1.jpeg'
import con2 from './images/con2.jpeg'
import con3 from './images/con3.jpeg'
import con4 from './images/con4.jpeg'
import con5 from './images/con5.jpeg'
const Ourstory = () => {

    useEffect(() => {
        // Add any necessary effect logic here
    }, []);

    return (
        <div>
            <Header />
            <br />

            <section className="ourstory-section about_page animated-section">
                <div className="container">
                    {/* Introduction */}
                    <div className="intro-card">
                        <img 
                            src={con1}
                            alt="Our Story"
                            className="intro-image"
                        />
                        <div className="intro-content">
                            <h4>Our Story</h4>
                            <p>
                                At N D Techland Private Limited, we are dedicated to delivering innovative software solutions that empower businesses to thrive in today’s competitive landscape. Our flagship product, N D Connect, is designed to revolutionize the way companies manage their operations, helping them streamline processes, improve efficiency, and achieve their business goals.
                            </p>
                        </div>
                    </div>

                    {/* Our Journey Card */}
                    <div className="info-card mt-5">
                        <div className="info-content">
                            <h3 className="section-heading">Our Journey</h3>
                            <p>
                                Founded with a vision to create cutting-edge technology solutions, N D Techland has grown into a trusted name in the software development industry. Our journey began with a simple idea: to make complex business operations more accessible and manageable for companies of all sizes. Over the years, we have expanded our expertise, refined our products, and built a team of passionate professionals who are committed to excellence.
                            </p>
                        </div>
                        <img 
                            src={con2}
                            alt="Our Journey"
                            className="info-image"
                        />
                    </div>

                    {/* Why Choose Us Card */}
                    <div className="info-card mt-5">
                        <img 
                            src={con3}
                            alt="Why Choose Us"
                            className="info-image"
                        />
                        <div className="info-content">
                            <h3 className="section-heading">Why Choose N D Techland?</h3>
                            <ul>
                                <li><strong>Comprehensive Solutions:</strong> N D Connect is an all-in-one platform that integrates multiple business functions, providing a seamless experience and reducing the need for multiple software tools.</li>
                                <li><strong>Customizable and Scalable:</strong> Our solutions are designed to grow with your business. Whether you’re a small startup or a large enterprise, N D Connect can be customized and scaled to meet your specific needs.</li>
                                <li><strong>Dedicated Support:</strong> We pride ourselves on offering exceptional customer support. Our team is always ready to assist you with any questions or challenges, ensuring that you get the most out of our products.</li>
                                <li><strong>Proven Track Record:</strong> With a history of successful projects and satisfied clients, N D Techland has established itself as a reliable partner for businesses looking to leverage technology for growth.</li>
                            </ul>
                        </div>
                    </div>

                    {/* Community and Social Responsibility Card */}
                    <div className="info-card mt-5">
                        <div className="info-content">
                            <h3 className="section-heading">Our Community and Social Responsibility</h3>
                            <p>
                                At N D Techland, we believe in giving back to the community. We are committed to corporate social responsibility initiatives that contribute to the well-being of society and the environment. Through various programs and partnerships, we strive to make a positive impact, supporting causes that align with our values.
                            </p>
                        </div>
                        <img 
                            src={con4}
                            alt="Community"
                            className="info-image"
                        />
                    </div>

                    {/* Join Us Card */}
                    <div className="info-card mt-5">
                        <img 
                            src={con5}
                            alt="Join Us"
                            className="info-image"
                        />
                        <div className="info-content">
                            <h3 className="section-heading">Join Us on Our Journey</h3>
                            <p>
                                As we continue to grow and innovate, we invite you to join us on our journey. Whether you’re a business looking for a powerful management solution or a professional seeking a dynamic career opportunity, N D Techland is the place for you. Together, we can achieve great things.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Ourstory;
