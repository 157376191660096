import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import business from './images/business.jpeg'
import futuretech from './images/futuretech.jpeg'
import promanagement from './images/promanagement.jpeg'
const Blog = () => {

  useEffect(() => {
    // Any necessary effects can be added here
  }, []);

  return (
    <div>
      <Header />

      <section className="blog-section py-10">
        <div className="container">
          <div className="text-center py-12">
            <div className="section-head">
              <h4><span>Our Blog</span></h4>
            </div>
            <h3 className="section-subtitle">Latest Blog Posts</h3>
            <p className="section-description">
              Stay informed with our blog, featuring the latest industry news, tips, and best practices for business management. Our articles are designed to help you stay ahead of the curve.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="card blog-card shadow-sm">
                <img src={futuretech}
                  className="card-img-top" alt="Blog 1" />
                <div className="card-body">
                  <h5 className="card-title">Understanding the Future of Tech</h5>
                  <p className="card-text">Explore how emerging technologies are shaping the future and what it means for businesses.</p>
                  <a href="#" className="btn btn-primary">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card blog-card shadow-sm">
                <img src={promanagement}
                  className="card-img-top" alt="Blog 2" />
                <div className="card-body">
                  <h5 className="card-title">Top 5 Tips for Effective Project Management</h5>
                  <p className="card-text">Discover the key strategies and tools for successful project management and achieving project goals.</p>
                  <a href="#" className="btn btn-primary">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card blog-card shadow-sm">
                <img src={business}
                  className="card-img-top" alt="Blog 3" />
                <div className="card-body">
                  <h5 className="card-title">How to Leverage Data for Business Growth</h5>
                  <p className="card-text">Learn how to harness the power of data analytics to drive business growth and make informed decisions.</p>
                  <a href="#" className="btn btn-primary">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Blog;
