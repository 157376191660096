import React from 'react';
import './Helpcenter.css'; // For custom styling
import Header from './Header';
import Footer from './Footer';

const Helpcenter = () => {
    return (
        <>
            <Header />

            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <section className="helpcenter-page">
                            <div className="container helpcenter-content">
                                {/* Header Section */}
                                <div className="helpcenter-header">
                                    <h1>Help Center</h1>
                                    <p>We're here to help you with any questions or concerns.</p>
                                </div>

                                {/* FAQ and Contact Section */}
                                <div className="helpcenter-sections">
                                    {/* FAQ Section */}
                                    <section className="helpcenter-faq">
                                        <h2>Frequently Asked Questions</h2>
                                        <div className="faq-item">
                                            <h3>How do I reset my password?</h3>
                                            <p>You can reset your password by clicking the "Forgot Password" link on the login page.</p>
                                        </div>
                                        <div className="faq-item">
                                            <h3>How do I contact support?</h3>
                                            <p>You can contact support by emailing support@example.com or using the contact form below.</p>
                                        </div>
                                        <div className="faq-item">
                                            <h3>Where can I find the user manual?</h3>
                                            <p>The user manual is available in the Resources section of your account dashboard.</p>
                                        </div>
                                    </section>

                                    {/* Contact Form */}
                                    <section className="helpcenter-contact">
                                        <h2>Contact Support</h2>
                                        <form className="contact-form">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" id="name" name="name" required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" id="email" name="email" required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="message">Message</label>
                                                <textarea id="message" name="message" rows="4" required></textarea>
                                            </div>
                                            <button type="submit" className="submit-btn">Submit</button>
                                        </form>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    );
}

export default Helpcenter;
