import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Otherservices = () => {
    return (
        <div>
            <Header />

            <section className="services-section">
                <div className="services-container container">
                    <div className="section-head col-sm-12">

                        <h4><span>Other Services</span></h4>

                    </div>
                    <div className="service-section">

                        <h3>Other Services by N D Techland Private Limited</h3>
                        <p>
                            At N D Techland, we understand that businesses require more than just software solutions to succeed in today’s complex environment. That’s why we offer a range of professional services designed to complement our software products and support your business in various areas. Our expertise extends beyond technology, providing you with a holistic approach to managing and growing your business.
                        </p>

                        <h3>1. Chartered Firm Services</h3>
                        <p>
                            Our Chartered Firm services are tailored to meet the financial and compliance needs of businesses of all sizes. We offer a wide range of accounting, auditing, taxation, and advisory services to help you manage your finances effectively and ensure compliance with regulatory requirements.
                        </p>
                        <ul>
                            <li>
                                <h5><b>Accounting & Bookkeeping</b></h5>
                                <p>
                                    We provide comprehensive accounting and bookkeeping services, ensuring that your financial records are accurate, up-to-date, and compliant with relevant standards. Our services include maintaining general ledgers, preparing financial statements, and reconciling accounts.
                                </p>
                            </li>
                            <li>
                                <h5><b>Taxation Services</b></h5>
                                <p>
                                    Navigating the complex tax landscape can be challenging. Our expert tax consultants help you manage your tax obligations efficiently, offering services such as tax planning, preparation, filing, and representation before tax authorities. We aim to minimize your tax liabilities while ensuring compliance with all applicable laws.
                                </p>
                            </li>
                            <li>
                                <h5><b>Audit & Assurance</b></h5>
                                <p>
                                    Our audit and assurance services are designed to give you confidence in your financial statements. We conduct thorough audits, reviews, and assessments of your financial operations, helping you identify risks, improve controls, and enhance transparency.
                                </p>
                            </li>
                            <li>
                                <h5><b>Financial Advisory</b></h5>
                                <p>
                                    Whether you’re planning to expand your business, manage cash flow, or secure funding, our financial advisory services provide you with the insights and strategies needed to make informed decisions. We offer tailored advice on mergers and acquisitions, business valuations, and financial restructuring.
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="service-section">
                        <h3>2. Law Firm Services</h3>
                        <p>
                            Legal matters are an integral part of any business, and having access to expert legal advice is crucial for long-term success. Our Law Firm services provide you with comprehensive legal support, covering a wide range of business-related legal issues.
                        </p>
                        <ul>
                            <li>
                                <h5><b>Corporate Law</b></h5>
                                <p>
                                    We offer legal services related to the formation, governance, and operation of businesses. Our corporate law services include company registration, drafting and reviewing contracts, shareholder agreements, mergers and acquisitions, and corporate restructuring.
                                </p>
                            </li>
                            <li>
                                <h5><b>Intellectual Property (IP) Law</b></h5>
                                <p>
                                    Protecting your intellectual property is essential in today’s competitive market. Our IP law services help you secure patents, trademarks, copyrights, and trade secrets. We also provide assistance with IP litigation and enforcement.
                                </p>
                            </li>
                            <li>
                                <h5><b>Employment Law</b></h5>
                                <p>
                                    Navigating employment laws can be complex, but our legal experts are here to help. We offer services related to drafting employment contracts, handling disputes, ensuring compliance with labor laws, and managing employee-related litigation.
                                </p>
                            </li>
                            <li>
                                <h5><b>Dispute Resolution</b></h5>
                                <p>
                                    Disputes can arise in any business, and resolving them effectively is critical to maintaining relationships and protecting your interests. Our dispute resolution services include mediation, arbitration, and litigation, helping you achieve favorable outcomes with minimal disruption to your business.
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="service-section">
                        <h3>3. Digital Marketing Services</h3>
                        <p>
                            In the digital age, an effective online presence is key to attracting and retaining customers. Our Digital Marketing services are designed to help you reach your target audience, build brand awareness, and drive sales through a strategic approach to online marketing.
                        </p>
                        <ul>
                            <li>
                                <h5><b>Search Engine Optimization (SEO)</b></h5>
                                <p>
                                    Improve your website’s visibility on search engines with our SEO services. We conduct keyword research, optimize on-page elements, and build high-quality backlinks to boost your search engine rankings and drive organic traffic to your site.
                                </p>
                            </li>
                            <li>
                                <h5><b>Pay-Per-Click (PPC) Advertising</b></h5>
                                <p>
                                    Reach potential customers quickly with targeted PPC campaigns. Our experts create and manage ads on platforms like Google AdWords and social media, optimizing them for maximum ROI. We handle everything from keyword selection to ad copy and bid management.
                                </p>
                            </li>
                            <li>
                                <h5><b>Social Media Marketing</b></h5>
                                <p>
                                    Engage with your audience and build your brand on social media platforms like Facebook, Instagram, LinkedIn, and Twitter. Our social media marketing services include content creation, community management, paid promotions, and analytics to measure success.
                                </p>
                            </li>
                            <li>
                                <h5><b>Content Marketing</b></h5>
                                <p>
                                    Content is at the heart of digital marketing. We create compelling content that resonates with your audience and supports your marketing goals. From blog posts and articles to videos and infographics, our content marketing services help you tell your brand’s story effectively.
                                </p>
                            </li>
                            <li>
                                <h5><b>Email Marketing</b></h5>
                                <p>
                                    Nurture leads and retain customers with personalized email campaigns. Our email marketing services include designing responsive email templates, writing engaging content, segmenting your audience, and analyzing campaign performance.
                                </p>
                            </li>
                            <li>
                                <h5><b>Website Development & Design</b></h5>
                                <p>
                                    Your website is often the first point of contact for potential customers. We offer website development and design services to create user-friendly, responsive, and visually appealing websites that reflect your brand and drive conversions.
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="service-section">
                        <h3>4. Public Relations (PR) & Marketing Services</h3>
                        <p>
                            Building a strong brand reputation and maintaining positive relationships with your stakeholders are essential for long-term success. Our PR and Marketing services are designed to help you communicate effectively with your audience, manage your brand’s image, and enhance your market presence.
                        </p>
                        <ul>
                            <li>
                                <h5><b>Media Relations</b></h5>
                                <p>
                                    We help you build and maintain relationships with key media outlets, ensuring that your brand receives positive coverage. Our media relations services include writing and distributing press releases, organizing press conferences, and securing media interviews.
                                </p>
                            </li>
                            <li>
                                <h5><b>Brand Management</b></h5>
                                <p>
                                    Protecting and enhancing your brand’s reputation is critical in today’s competitive market. Our brand management services include brand strategy development, reputation management, crisis communication, and brand monitoring.
                                </p>
                            </li>
                            <li>
                                <h5><b>Event Management</b></h5>
                                <p>
                                    Events are a powerful way to connect with your audience and promote your brand. We offer end-to-end event management services, including planning, organizing, and executing corporate events, product launches, conferences, and trade shows.
                                </p>
                            </li>
                            <li>
                                <h5><b>Influencer Marketing</b></h5>
                                <p>
                                    Leverage the power of influencers to reach new audiences and build credibility. We identify the right influencers for your brand, develop collaborative campaigns, and measure the impact of influencer partnerships on your business.
                                </p>
                            </li>
                            <li>
                                <h5><b>Crisis Management</b></h5>
                                <p>
                                    In the event of a crisis, effective communication is key to minimizing damage and maintaining trust. Our crisis management services include crisis planning, communication strategy development, and media handling to help you navigate challenging situations.
                                </p>
                            </li>
                            <li>
                                <h5><b>Strategic Marketing Consulting</b></h5>
                                <p>
                                    Our marketing experts work with you to develop and implement a comprehensive marketing strategy that aligns with your business goals. We analyze market trends, identify opportunities, and create a roadmap for your marketing efforts to maximize ROI.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="tailored-solutions">
                        <h3>Tailored Solutions for Your Business</h3>
                        <p>
                            At N D Techland, we believe that every business is unique, and so are its needs. That’s why we offer tailored solutions across our services, ensuring that you receive the support and expertise required to achieve your specific objectives. Whether you’re looking to optimize your finances, navigate legal challenges, enhance your digital presence, or build your brand’s reputation, we have the skills and experience to help you succeed.
                        </p>
                    </div>

                    <div className="get-in-touch">
                        <h3>Get in Touch</h3>
                        <p>
                            Ready to take your business to the next level? Contact us today to discuss your requirements and learn more about how our services can benefit your organization. Our team is here to answer your questions and provide you with the guidance you need to make informed decisions.
                        </p>
                    </div>


                </div>


            </section >
            <Footer />
        </div >
    );
}

export default Otherservices;
