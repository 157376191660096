import React from 'react';
import Header from './Header';
import Footer from './Footer';
import con16 from './images/con16.jpeg'
import con17 from './images/con17.jpeg'
import con18 from './images/con18.jpeg'
import con19 from './images/con19.jpeg'
const Featurebenifits = () => {
    // Dummy image URL
    const dummyImage = "https://via.placeholder.com/500x300";

    return (
        <div>
            <Header />
            <section className="about-section three-image about_page animated-section">
                <div className="container d-flex flex-column align-items-center justify-content-center">
                    <div className="section-head col-sm-12 text-center">
                        <h4><span>Features & Benefits</span></h4>
                    </div>

                    <div className="row feature-row align-items-center mb-5">
                        <div className="col-md-6 text-center text-md-left">
                            <h3>Customer Management</h3>
                            <p>
                                Keep track of all your customer interactions in one place. N D Connect allows you to store customer details, manage contact information, track communication history, and analyze customer behavior. This feature helps you build stronger relationships with your customers by providing personalized service and timely follow-ups.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src={con16}
                             alt="Customer Management" className="img-fluid rounded" />
                        </div>
                    </div>

                    <div className="row feature-row align-items-center mb-5">
                        <div className="col-md-6 order-md-2 text-center text-md-left">
                            <h3>Invoicing and Payment Management</h3>
                            <p>
                                Simplify your billing process with N D Connect’s invoicing system. You can generate professional invoices in seconds, send them to clients directly from the platform, and track payments in real-time. The system also includes automated payment reminders, ensuring that you get paid on time without the need for manual follow-up.
                            </p>
                        </div>
                        <div className="col-md-6 order-md-1 text-center">
                            <img src={con17} alt="Invoicing and Payment Management" className="img-fluid rounded" />
                        </div>
                    </div>

                    <div className="row feature-row align-items-center mb-5">
                        <div className="col-md-6 text-center text-md-left">
                            <h3>AMC (Annual Maintenance Contract) Reminder</h3>
                            <p>
                                Never miss a renewal or maintenance deadline again. N D Connect’s AMC Reminder feature keeps track of all your maintenance contracts, alerting you in advance of upcoming renewals. This feature is especially useful for businesses that offer recurring services or need to manage long-term customer contracts.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src={con18} alt="AMC Reminder" className="img-fluid rounded" />
                        </div>
                    </div>

                    <div className="row feature-row align-items-center mb-5">
                        <div className="col-md-6 order-md-2 text-center text-md-left">
                            <h3>Employee Management</h3>
                            <p>
                                Manage your workforce with ease using N D Connect’s employee management tools. Store employee profiles, track performance, manage roles and responsibilities, and keep records of training and certifications. The platform also allows for easy communication with your team, ensuring everyone is on the same page.
                            </p>
                        </div>
                        <div className="col-md-6 order-md-1 text-center">
                            <img src={con19} alt="Employee Management" className="img-fluid rounded" />
                        </div>
                    </div>

                    {/* Repeat the pattern for the remaining features */}

                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Featurebenifits;
