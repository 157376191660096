import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './Events.css';
import 'react-calendar/dist/Calendar.css';

const Events = () => {
  const [hoveredDate, setHoveredDate] = useState(null);

  const events = [
    {
      entertainment: 'Your Tittle',
      title: 'Improving Employee Performance Monitoring',
      date: new Date(2024, 9, 10),
    },
    {
      entertainment: 'Profile',
      title: 'Innovative HR Analytics & Tools for 2024',
      date: new Date(2024, 10, 5),
    },
    {
      entertainment: 'Conference',
      title: 'Boosting Employee Engagement with Dashboards',
      date: new Date(2024, 8, 15),
    },
  ];

  const onDateHover = (date) => {
    const event = events.find((event) => event.date.toDateString() === date.toDateString());
    setHoveredDate(event || null);
  };

  return (
    <div className="events-calendar">
      <Calendar
        onActiveStartDateChange={() => setHoveredDate(null)}
        tileContent={({ date }) => {
          const event = events.find((event) => event.date.toDateString() === date.toDateString());
          return (
            <div
              onMouseEnter={() => onDateHover(date)}
              onMouseLeave={() => setHoveredDate(null)}
              className={`event-marker ${event ? 'has-event' : ''}`}
            >
              {event && <span className="dot"></span>}
            </div>
          );
        }}
      />

      {hoveredDate && (
        <div className="event-details-popup" style={{marginTop:"-6rem", marginLeft :"6rem"}}>
          <div className="popup-content">
            <div className="popup-header">
              <h3 className="popup-title">{hoveredDate.title}</h3>
              <p className="popup-date">{hoveredDate.date.toDateString()}</p>
            </div>
            <div className="popup-body">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/46557/Time.svg"
                alt="Time"
                className="popup-icon"
              />
              <p className="popup-entertainment">{hoveredDate.entertainment}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Events;
