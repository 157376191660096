import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import demo from './images/demo.jpeg'
import feature from './images/feature.jpeg'
const Requestdemo = () => {

    useEffect(() => {
        // Add any necessary effect logic here
    }, []);

    return (
        <div>
            <Header />

            <section className="request-demo-section ">
                <div className="container">

                    <div className="section-head text-center mb-4">
                        <h4><span>Request a Demo</span></h4>
                    </div>

                    <div className="text-center mb-5">
                        <h3 className="section-subtitle">Experience the Power of N D Connect</h3>
                        <p className="section-description">
                            Experience the power of N D Connect firsthand with a personalized demo. Our team will walk you through the platform, showing you how it can be tailored to meet your business’s unique needs.
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 mb-4">
                            <div className="card demo-card shadow-sm">
                                <img src={demo} className="card-img-top" alt="Key Features" />
                                <div className="card-body">
                                    <h5 className="card-title">Key Features Overview</h5>
                                    <p className="card-text">
                                        Get a detailed overview of key features, see how the platform works in real-time, and have the opportunity to ask any questions you may have.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <div className="card demo-card shadow-sm">
                                <img src={feature}
                                 className="card-img-top" alt="Request a Demo" />
                                <div className="card-body">
                                    <h5 className="card-title">How to Request a Demo</h5>
                                    <p className="card-text">
                                        To request a demo, fill out the form on our website or contact us at <a href="mailto:sales@ndtechland.com">sales@ndtechland.com</a>. We’ll schedule a convenient time for your demo.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center mt-5">
                        <p className="section-description mb-4">
                            Our demos are tailored to your business’s needs, ensuring you get the most out of N D Connect.
                        </p>
                        <a href="/request-demo" className="btn btn-primary">Request a Demo Now</a>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Requestdemo;
