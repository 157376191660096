import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Attendance.css';

const Modal = ({ isOpen, onClose, attendanceDetail }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content attendance-modal">
        <h2>Attendance Details</h2>
        {attendanceDetail ? (
          <table className="table-responsive attendance-detail-table">
            <tbody>
              <tr>
                <td><strong>Office Hours:</strong></td>
                <td>{attendanceDetail.officeHour || 'N/A'}</td>
              </tr>
              <tr>
                <td><strong>Total Working Hours:</strong></td>
                <td>{attendanceDetail.totalWorkingHours || 'N/A'}</td>
              </tr>
              <tr>
                <td><strong>Monthly Working Hours:</strong></td>
                <td>{attendanceDetail.monthlyWorkingHours || 'N/A'}</td>
              </tr>
              <tr>
                <td><strong>Presence Percentage:</strong></td>
                <td>{attendanceDetail.presencePercentage || 'N/A'}</td>
              </tr>
              <tr>
                <td><strong>Absence Percentage:</strong></td>
                <td>{attendanceDetail.absencePercentage || 'N/A'}</td>
              </tr>
              <tr>
                <td><strong>Overtime Working Hours:</strong></td>
                <td>{attendanceDetail.overtimeWorkingHours || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>No details available</p>
        )}
        <button className='w-100 btn btn-primary btn-lg' onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [checkIn, setCheckIn] = useState('N/A');
  const [checkOut, setCheckOut] = useState('N/A');
  const [loginStatus, setLoginStatus] = useState('N/A');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [attendanceDetail, setAttendanceDetail] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 2;

  useEffect(() => {
    const fetchAttendanceData = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await axios.get('https://api.ndtechland.com/api/EmployeeApi/WebEmpLoginactivity', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.succeeded && response.data.data) {
          const data = response.data.data;
          setCheckIn(data[0]?.checkIN || 'N/A');
          setCheckOut(data[0]?.checkOut || 'N/A');
          setLoginStatus(data[0]?.loginStatus || 'N/A');
          setAttendanceData(data);
          setFilteredData(data);
        } else {
          setError('No attendance data found.');
        }
      } catch (error) {
        setError('Error fetching attendance records');
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, []);

  const openModal = async () => {
    setModalOpen(true);

    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://api.ndtechland.com/api/EmployeeApi/Empattendancedatail', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.succeeded && response.data.data) {
        setAttendanceDetail(response.data.data);
      } else {
        setAttendanceDetail({});
      }
    } catch (error) {
      setAttendanceDetail({});
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(filteredData.length / rowsPerPage) - 1));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);

    if (date) {

      const filtered = attendanceData.filter(record => record.currentdate === date);
      setFilteredData(filtered);
      setCurrentPage(0);
    } else {
      setFilteredData(attendanceData);
    }
  };

  const paginatedData = filteredData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

  return (
    <div className="attendance-container">
      <header className="attendance-header">
        <h3>Attendance Management</h3>
        <p style={{ color: "white" }}>Track and manage attendance efficiently</p>
      </header>

      <main className="attendance-content">
        <section className="attendance-records">
          <h2>Attendance Records</h2>

          <div className="date-filter">
            <input
              type="text"
              value={selectedDate}
              onChange={handleDateChange}
              placeholder="Enter date (DD-MM-YYYY)"
              className="form-control"
            />
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p style={{ color: 'red' }}>{error}</p>
          ) : (
            <div className="table-responsive">
              <table className="">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>CheckIn</th>
                    <th>CheckOut</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.length === 0 ? (
                    <tr>
                      <td colSpan="5">No login activities found</td>
                    </tr>
                  ) : (
                    paginatedData.map((record, index) => (
                      <tr key={index}>
                        <td>{record.currentdate || 'N/A'}</td>
                        <td>{record.loginStatus || 'N/A'}</td>
                        <td>{record.checkIN || 'N/A'}</td>
                        <td>{record.checkOut || 'N/A'}</td>
                        <td>
                          <button className="view-details-button" onClick={openModal}>
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <button className="pagination-button" onClick={handlePrevPage} disabled={currentPage === 0}>Previous</button>
                <span className="pagination-text">Page {currentPage + 1} of {Math.ceil(filteredData.length / rowsPerPage)}</span>
                <button className="pagination-button" onClick={handleNextPage} disabled={(currentPage + 1) * rowsPerPage >= filteredData.length}>Next</button>
              </div>
            </div>
          )}
        </section>
      </main>
      <Modal isOpen={modalOpen} onClose={closeModal} attendanceDetail={attendanceDetail} />
    </div>
  );
};

export default Attendance;
