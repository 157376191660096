import React from 'react';
import Header from './Header';
import Footer from './Footer';
import con6 from './images/con6.jpeg'
import con7 from './images/con7.jpeg'
const Missionvission = () => {
    return (
        <div>
            <Header />

            <section className="about-section about_page animated-section">
                <div className="section-head col-sm-12">
                    <h4><span>Mission & Vission</span></h4>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <img
                                src={con6}
                                alt="Our Mission"
                                className="img-fluid rounded mb-4"
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="section-head">
                                <h5><b>Our Mission</b></h5>
                                
                                <p style={{ textAlign: "left" }}>
                                    Our mission is to empower businesses by providing them with the tools they need to succeed. We believe that technology should simplify, not complicate, and that every business deserves access to powerful, user-friendly software that drives growth. With N D Connect, we aim to offer an all-in-one platform that addresses the diverse needs of modern businesses, enabling them to focus on what they do best.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center mt-5">
                        <div className="col-lg-6 order-lg-2">
                            <img
                                src={con7}
                                alt="Our Vision"
                                className="img-fluid rounded mb-4"
                            />
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <div className="section-head">
                            <h5><b>Our Vission</b></h5>
                                <p style={{ textAlign: "left" }}>
                                    We envision a future where businesses of all sizes can harness the power of technology to operate more efficiently, make smarter decisions, and achieve their full potential. At N D Techland, we are committed to continuous innovation, ensuring that our products evolve with the changing needs of our clients and the dynamic market landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Missionvission;
