import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <footer>
                <div className="footer section-b-space section-t-space">
                    <div className="container">
                        <div className="row order-row">
                            <div className="col-xl-4 col-md-6 order-cls">
                                <div className="footer-title mobile-title">
                                    <h5>Contact Us</h5>
                                </div>
                                <div className="footer-content" style={{ textAlign: "left" }}>
                                    <div className="contact-detail">
                                        <div className="footer-logo">
                                            <Link to="/"><img
                                                src="../assets/images/icon/logo01.png"
                                                alt="Abhyaranyan Farmstay Logo"
                                                className="img-fluid lazyload"
                                                style={{ height: 66, width: 198 }}
                                            /></Link>
                                        </div>
                                        <p>
                                            We make experience that are alluring, easy to utilize, and drive results for your organization. We are not your typical web development organization.
                                        </p>
                                        <ul className="contact-list" style={{ marginLeft: "-2rem" }}>
                                            <li>
                                                <i className="fas fa-map-marker-alt" /> C - 53, First Floor, Sector 2, Noida, Uttar Pradesh - 201301
                                            </li>
                                            <li>
                                                <i className="fas fa-phone-alt" /> 0120 4354103
                                            </li>
                                            <li>
                                                <i className="fas fa-envelope" /> info@ndtechland.com
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3">
                                <div className="footer-space" style={{ textAlign: "left" }}>
                                    <div className="footer-title">
                                        <h5>Legal</h5>
                                    </div>
                                    <div className="footer-content">
                                        <div className="footer-links">
                                            <ul style={{ marginLeft: "-2rem" }}>
                                                <li>
                                                    <Link to="/Termsconditions"> <a>Terms of Services</a></Link>
                                                </li>

                                                <li>
                                                    <Link to="/Privacypolicy"><a href="#">Privacy Policy</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Cookiepolicy"><a href="#">Cookie Policy</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Datasecurity">  <a href="#">Data Security</a></Link>
                                                </li>

                                                <li>
                                                    <Link to="/Refunpolicy">  <a href="#">Refund Policy</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Datasafety">  <a href="#">Data Safety and Web Security</a></Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3 order-cls" style={{ textAlign: "left" }}>
                                <div className="footer-space">
                                    <div className="footer-title">
                                        <h5>Support</h5>
                                    </div>
                                    <div className="footer-content">
                                        <div className="footer-links">
                                            <ul style={{ marginLeft: "-2rem" }}>
                                                <li>
                                                    <Link to="/Helpcenter"> <a>Help Center</a></Link>
                                                </li>

                                                <li>
                                                    <Link to="/Faq"><a href="#">FAQs </a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Customersupport"><a href="#">Customer Support</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Userguide"> <a href="#">User Guides
                                                    </a></Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3">
                                <div className="footer-title">
                                    <h5>Other Services</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="footer-links">
                                        <ul style={{ marginLeft: "-2rem" }}>
                                            <li>
                                                <Link to="/Chartedfirmservices"> <a>Chartered Firm Services</a></Link>
                                            </li>

                                            <li>
                                                <Link to="/Lawfirmservices"><a href="#">Law Firm Services</a></Link>
                                            </li>
                                            <li>
                                                <Link to="/Digitalmarketing"><a href="#">Digital Marketing Services</a></Link>
                                            </li>
                                            <li>
                                                <Link to="/Publicrelation"> <a href="#"> PR & Marketing Services
                                                </a></Link>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-6">
                                <div className="footer-title">
                                    <h5>Follow Us</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="footer-links">
                                        <ul style={{ marginLeft: "-2rem" }}>
                                            <li>
                                                <Link to="https://in.linkedin.com/company/nd-techland" target='blank'> <a>LinkedIn</a></Link>
                                            </li>

                                            <li>
                                                <Link to="https://www.facebook.com/ndtechland/" target='blank'><a >Facebook</a></Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.instagram.com/ndtechland/" target='blank'><a>Instagram</a></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub-footer">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-12 col-md-6 col-sm-12">
                                <div className="copy-right">
                                    <p style={{ textAlign: "center" }}>
                                        Copyright 2024-2025 by <i className="fas fa-heart" /> N D Techland Pvt Ltd
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
