import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Taskmanager.css';
import Select from 'react-select';
import Swal from 'sweetalert2';

const Taskmanager = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTask, setSelectedTask] = useState(null);
  const [reply, setReply] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskModules, setTaskModules] = useState({}); // Store task-specific module selections
  const [taskModuleList, setTaskModuleList] = useState([]);
  const token = localStorage.getItem('token');

  // Fetch tasks
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('https://api.ndtechland.com/api/EmployeeApi/EmpTasksassign', {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.succeeded) {
          const apiTasks = response.data.data.map(task => ({
            id: task.id,
            title: task.taskTittle,
            description: task.taskDescription,
            startDate: new Date(task.taskstartdate.split('/').reverse().join('-')),
            endDate: new Date(task.taskEnddate.split('/').reverse().join('-')),
            completed: task.taskStatus === 'Completed',
            reply: ''
          }));

          setTasks(apiTasks);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, [token]);

  // Fetch task modules by task ID
  const fetchTaskModules = async (taskId) => {
    try {
      const response = await axios.get(`https://api.ndtechland.com/api/EmployeeApi/EmpTasksassignbyid?id=${taskId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.succeeded) {
        const modules = response.data.data.empdata.map((module) => ({
          value: module.id,
          label: module.tasksubTittle,
        }));

        setTaskModuleList(modules);
      }
    } catch (error) {
      console.error('Error fetching task modules:', error);
    }
  };

  // Handle task click and open modal
  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
    fetchTaskModules(task.id);
  };

  // Handle modal close
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
    setTaskModuleList([]);
    setReply('');
  };

  // Handle module selection change
  const handleModuleChange = (selectedOptions) => {
    setTaskModules((prevModules) => ({
      ...prevModules,
      [selectedTask.id]: selectedOptions
    }));
  };

  // Handle submitting the reply and task assignment
  // Handle submitting the reply and task assignment
  const handleSubmitReply = async () => {
    const selectedModuleIds = taskModules[selectedTask.id]?.map(module => module.value) || [];

    // Create a FormData object
    const formData = new FormData();

    // Append the necessary data to the FormData object
    formData.append('token', token);
    formData.append('subtaskid', JSON.stringify([selectedTask.id])); // Convert array to JSON string
    formData.append('taskid', JSON.stringify(selectedModuleIds)); // Convert array to JSON string
    formData.append('Taskreason', reply);

    // Use the checkbox value for task status
    formData.append('Taskstatus', selectedTask.completed ? 'Completed' : 'UnCompleted');

    try {
      const response = await axios.post('https://api.ndtechland.com/api/EmployeeApi/AddTasksassign', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data' // Specify that the request body is FormData
        }
      });

      if (response.data.succeeded) {
        const updatedTasks = tasks.map(task =>
          task.id === selectedTask.id ? { ...task, reply, completed: selectedTask.completed } : task
        );
        setTasks(updatedTasks);
        setReply(''); // Clear reply after submission
        setIsModalOpen(false); // Close modal after submission

        // Show SweetAlert success message
        Swal.fire({
          title: 'Success',
          text: "Task Detail's submitted successfully",
          icon: 'success',
          confirmButtonText: 'OK'
        });

      } else {
        console.error('Error adding task assignment:', response.data.message);
      }
    } catch (error) {
      console.error('Error submitting task assignment:', error);
    }
  };

  // Filter tasks for the selected date
  const tasksForSelectedDate = tasks.filter(
    task => selectedDate >= task.startDate && selectedDate <= task.endDate
  );

  return (
    <div className="task-manager">
      <h1 className="task-manager__title">Task Manager</h1>

      <div className="task-manager__layout">
        <aside className="task-manager__sidebar">
          <Calendar
            onChange={setSelectedDate}
            value={selectedDate}
            tileClassName={({ date, view }) => {
              if (view === 'month') {
                const sameDay = (d1, d2) =>
                  d1.getFullYear() === d2.getFullYear() &&
                  d1.getMonth() === d2.getMonth() &&
                  d1.getDate() === d2.getDate();

                const isToday = sameDay(date, new Date());

                const hasTask = tasks.some(
                  task =>
                    sameDay(date, task.startDate) ||
                    (date > task.startDate && date < task.endDate) ||
                    sameDay(date, task.endDate)
                );

                if (hasTask) {
                  return 'task-assigned';
                } else if (isToday) {
                  return 'current-date';
                }
              }
              return '';
            }}
          />
        </aside>

        <main className="task-manager__content">
          <h2>Tasks for {selectedDate.toDateString()}</h2>
          <div className="task-manager__cards">
            {tasksForSelectedDate.length > 0 ? (
              tasksForSelectedDate.map((task) => (
                <div
                  key={task.id}
                  className={`task-manager__card ${task.completed ? 'completed' : 'pending'}`}
                  onClick={() => handleTaskClick(task)}
                >
                  <div className="task-manager__text">
                    <div className="task-manager__header">
                      <h3>{task.title}</h3>
                      <p className={`task-manager__status ${task.completed ? 'status-completed' : 'status-pending'}`}>
                        {task.completed ? 'Completed' : 'Pending'}
                      </p>
                    </div>
                    <p className="task-manager__description">{task.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No tasks for this date.</p>
            )}
          </div>
        </main>
      </div>

      {isModalOpen && selectedTask && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Task Details</h5>
                <button type="button" className="close" onClick={handleModalClose} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h5>{selectedTask.title}</h5>
                <p>{selectedTask.description}</p>
                <p><strong>Start Date:</strong> {selectedTask.startDate.toDateString()}</p>
                <p><strong>End Date:</strong> {selectedTask.endDate.toDateString()}</p>

                <Select
                  isMulti
                  options={taskModuleList}
                  onChange={handleModuleChange}
                  placeholder="Select Modules"
                />

                <textarea
                  className="form-control mt-3"
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  placeholder="Add your reply..."
                  rows="4"
                />
              </div>
              <div className="modal-footer d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="completedCheckbox"
                    checked={selectedTask.completed}
                    onChange={(e) => setSelectedTask({ ...selectedTask, completed: e.target.checked })}
                  />
                  <label className="form-check-label" htmlFor="completedCheckbox">
                    Completed
                  </label>
                </div>

                <div>
                  <button type="button" className="btn btn-secondary me-2" onClick={handleModalClose}>
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={handleSubmitReply}>
                    Submit
                  </button>
                </div>

              </div>


            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Taskmanager;
