import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import health from './images/health.jpeg'
import Ecom from './images/Ecom.jpeg'
import financial from './images/financial.jpeg'
const Casestudy = () => {

  useEffect(() => {
    // Any necessary effects can be added here
  }, []);

  return (
    <>
      <Header />

      <section className="casestudy-section ">
        <div className="container">
          <div className="section-head col-sm-12">
            <h4><span>Our Case Studies</span></h4>
          </div>
          <div className="text-center ">
            <h3 className="section-subtitle">Recent Success Stories</h3>
            <p className="section-description">
              Discover how N D Connect has transformed businesses across industries. Explore our case studies to see the real-world impact of our platform.
            </p>
          </div>

          <div className="row g-4">
            <div className="col-md-6 col-lg-4">
              <div className="case-study-item position-relative">
                <img src={Ecom}

                  className="img-fluid w-100" alt="Case Study 1" />
                <div className="case-study-overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
                  <div className="text-center p-3 " style={{ marginTop: "15rem" }}>
                    <h5 className="text-white">Optimizing E-Commerce Operations</h5>
                    <p className="text-white small">We improved user experience and boosted sales by 25%.</p>
                    <a href="#" className="btn btn-outline-light btn-sm">Read More</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="case-study-item position-relative">
                <img src={financial}

                  className="img-fluid w-100" alt="Case Study 2" />
                <div className="case-study-overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
                  <div className="text-center p- " style={{ marginTop: "15rem" }}>
                    <h5 className="text-white">Revolutionizing Financial Reporting</h5>
                    <p className="text-white small">Enhanced accuracy and speed for a major corporation.</p>
                    <a href="#" className="btn btn-outline-light btn-sm">Read More</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="case-study-item position-relative">
                <img src={health}

                  className="img-fluid w-100" alt="Case Study 3" />
                <div className="case-study-overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
                  <div className="text-center p-3" style={{ marginTop: "15rem" }}>
                    <h5 className="text-white">Enhancing Healthcare Management</h5>
                    <p className="text-white small">Improved patient care and efficiency at a leading hospital.</p>
                    <a href="#" className="btn btn-outline-light btn-sm">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Casestudy;
