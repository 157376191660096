import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import con13 from './images/con13.jpeg'
import con14 from './images/con14.jpeg'
const Expertise = () => {

  useEffect(() => {
    // Any effect logic can go here
  }, []);

  return (
    <div>
      <Header />
      <br />
      <section className="about-section about_page animated-section">
        <div className="section-head col-sm-12">

          <h4><span>Our Expertise</span></h4>

        </div>
        <div className="container">

          <div className="card-section mb-5">

            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="card-content">


                  <h3>Our Team of Professionals</h3>
                  <p>
                    N D Techland boasts a diverse team of skilled professionals, including software developers, designers, project managers, and customer support specialists. Our team’s expertise spans various industries, enabling us to understand the unique challenges and opportunities that businesses face. This knowledge allows us to create solutions that are not only technically robust but also aligned with the specific needs of our clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={con13}
                  alt="Our Team Expertise"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>

          {/* Innovation Section */}
          <div className="card-section">
            <div className="row">
              <div className="col-lg-6 order-lg-2">
                <img
                  src={con14}
                  alt="Innovation"
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <div className="card-content">
                  <h3>Our Commitment to Innovation</h3>
                  <p>
                    Innovation is at the core of our business. We continuously invest in research and development to ensure that our products remain at the forefront of technology. By staying up-to-date with the latest trends and advancements, we can offer our clients cutting-edge tools that give them a competitive edge in their respective markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Expertise;
