import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Corevalue.css'; // Import your CSS file for additional styling
import con8 from './images/con8.jpeg'
import con9 from './images/con9.jpeg'
import con10 from './images/con10.jpeg'
import con11 from './images/con11.jpeg'
import con12 from './images/con12.jpeg'
function Corevalues() {

    useEffect(() => {
        // Any necessary effects can be added here
    }, []);

    return (
        <div>
            <Header />

           
            <section className="corevalues-section about_page animated-section">
                <div className="container">
                    <div className="section-head text-center">
                        <h4><span>Our Core Values</span></h4>
                        <p>
                            At the heart of everything we do are our core values, which guide our decisions, actions, and interactions with our clients, partners, and team members:
                        </p>
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-4 mb-4">
                            <div className="corevalue-card">
                                <img 
                                    src={con8}
                                    alt="Innovation" 
                                    className="img-fluid rounded"
                                />
                                <div className="corevalue-content">
                                    <h5>Innovation</h5>
                                    <p>
                                        We strive to stay ahead of the curve by developing cutting-edge solutions that meet the ever-evolving demands of the business world.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <div className="corevalue-card">
                                <img 
                                    src={con9}
                                    alt="Quality" 
                                    className="img-fluid rounded"
                                />
                                <div className="corevalue-content">
                                    <h5>Quality</h5>
                                    <p>
                                        We are committed to delivering products and services of the highest quality, ensuring that our clients receive exceptional value and reliability.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <div className="corevalue-card">
                                <img 
                                    src={con10}
                                    alt="Integrity" 
                                    className="img-fluid rounded"
                                />
                                <div className="corevalue-content">
                                    <h5>Integrity</h5>
                                    <p>
                                        We operate with transparency, honesty, and accountability in all our dealings, building trust and long-term relationships with our clients.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <div className="corevalue-card">
                                <img 
                                    src={con11}
                                    alt="Customer-Centricity" 
                                    className="img-fluid rounded"
                                />
                                <div className="corevalue-content">
                                    <h5>Customer-Centricity</h5>
                                    <p>
                                        Our clients are at the center of everything we do. We listen to their needs, understand their challenges, and tailor our solutions to help them succeed.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <div className="corevalue-card">
                                <img 
                                    src={con12}
                                    alt="Collaboration" 
                                    className="img-fluid rounded"
                                />
                                <div className="corevalue-content">
                                    <h5>Collaboration</h5>
                                    <p>
                                        We believe in the power of teamwork, both within our organization and in our partnerships with clients. Together, we achieve more.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Corevalues;
