import React from 'react';
import './Userguide.css'; // Custom styling
import Header from './Header';
import Footer from './Footer';

const Userguide = () => {
    return (
        <>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                    <section className="user-guide-section">
                        <div className="container">
                            <h1>User Guide</h1>

                    

                            {/* Video Tutorials Section */}
                            <div className="tutorial-section">
                                <h2>Video Tutorials</h2>
                                <div className="tutorial-list">
                                    <div className="tutorial-item">
                                        <h3>Getting Started with Our Platform</h3>
                                        <iframe
                                            src="https://www.youtube.com/embed/samplevideo1"
                                            title="Getting Started"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <div className="tutorial-item">
                                        <h3>How to Manage Your Account</h3>
                                        <iframe
                                            src="https://www.youtube.com/embed/samplevideo2"
                                            title="Account Management"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <div className="tutorial-item">
                                        <h3>Advanced Features Overview</h3>
                                        <iframe
                                            src="https://www.youtube.com/embed/samplevideo3"
                                            title="Advanced Features"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>

                            {/* FAQ Section */}
                            <div className="faq-section">
                                <h2>Frequently Asked Questions</h2>
                                <div className="faq-item">
                                    <h3>How do I reset my password?</h3>
                                    <p>You can reset your password by clicking the "Forgot Password" link on the login page.</p>
                                </div>
                                <div className="faq-item">
                                    <h3>How can I update my profile information?</h3>
                                    <p>You can update your profile information by going to the "Account Settings" section in your dashboard.</p>
                                </div>
                                <div className="faq-item">
                                    <h3>What should I do if I encounter an issue?</h3>
                                    <p>If you encounter any issues, please contact support at support@example.com.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section >
            <Footer />
    </>
  );
};

export default Userguide;
