import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUser, faBank, faFileAlt, faClipboardCheck, faTasks, faCalendarAlt, faCog, faLock } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Header from '../Header';
import Profile from './Profile';
import Bankdetail from './Bankdetail';
import './Dashboard.css';
import Attendance from './Attendance';
import Taskmanager from './Taskmanager';
import Events from './Events';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Changepassword from '../Changepassword';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Modal, Button } from 'react-bootstrap';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const Dashboards = (employeeData) => {
    const [activeTab, setActiveTab] = useState('dashboard');
    const handleTabClick = (tab, link) => {
        setActiveTab(tab);
        window.open(link, '_blank');
    };

    const baseUrl = 'https://api.ndtechland.com';
    const [dashboardData, setDashboardData] = useState({
        totalAttendance: 0,
        attendance: '',
        leaveLeft: 0,
        leave: 0
    });

    const singleLeaveRef = useRef(null);
    const multipleLeaveRef = useRef(null);
    const singleLeaveDatesRef = useRef(null);
    const multipleLeaveDatesRef = useRef(null);
    const [leaveType, setLeaveType] = useState("single");
    const [leaveCategory, setLeaveCategory] = useState("");
    const [leaveTypeForStartDate, setLeaveTypeForStartDate] = useState("");
    const [leaveTypeForEndDate, setLeaveTypeForEndDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [reason, setReason] = useState("");
    const [leaveList, setLeaveList] = useState([]);
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const token = localStorage.getItem("token");
    const [documents, setDocuments] = useState({
        offerLetter: '',
        appointmentLetter: ''
    });

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get(`${baseUrl}/api/EmployeeApi/EmployeeDashboard`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.data.succeeded) {
                    const { offerletter, appointmentletter } = response.data.data;
                    setDocuments({
                        offerLetter: `${baseUrl}${offerletter}`,
                        appointmentLetter: `${baseUrl}${appointmentletter}`
                    });
                }
            })
            .catch(error => {
                console.error("There was an error fetching the dashboard data!", error);
            });
    }, []);


    $(document).ready(function () {
        // Handle year selection
        $('#selectYear').change(function () {
            $('#yearLabel').text($(this).val());
            showSelectedInfo();
        });

        // Handle month button click
        $('.btn-outline-primary').click(function () {
            $('.btn-outline-primary').removeClass('active');
            $(this).addClass('active');
            $('#monthLabel').text($(this).text());
            showSelectedInfo();
        });

        function showSelectedInfo() {
            if ($('#selectYear').val() && $('.btn-outline-primary.active').length > 0) {
                $('#selectedMonthYear').show();
            }
        }
    });


    useEffect(() => {
        if (singleLeaveRef.current) {
            singleLeaveRef.current.addEventListener("click", () => {
                if (singleLeaveDatesRef.current && multipleLeaveDatesRef.current) {
                    singleLeaveDatesRef.current.classList.remove("d-none");
                    multipleLeaveDatesRef.current.classList.add("d-none");
                }
            });
        }

        // Attach event listener to the multiple leave button
        if (multipleLeaveRef.current) {
            multipleLeaveRef.current.addEventListener("click", () => {
                if (singleLeaveDatesRef.current && multipleLeaveDatesRef.current) {
                    singleLeaveDatesRef.current.classList.add("d-none");
                    multipleLeaveDatesRef.current.classList.remove("d-none");
                }
            });
        }

        // Cleanup event listeners on component unmount
        return () => {
            if (singleLeaveRef.current) {
                singleLeaveRef.current.removeEventListener("click", () => { });
            }
            if (multipleLeaveRef.current) {
                multipleLeaveRef.current.removeEventListener("click", () => { });
            }
        };
    }, []);

    const [employeeData1, setEmployeeData1] = useState({
        fullName: '',
        personalEmailAddress: '',
        empProfile: ''
    });


    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.ndtechland.com/api/EmployeeApi/GetEmployeeBasicInfo', {
            headers: {
                Authorization: `Bearer ${token}` // Pass the token in the Authorization header
            }
        })
            .then(response => {
                console.log('API Response:', response.data); // Debugging: Log the API response

                if (response.data && response.data.data) {
                    const { fullName, personalEmailAddress, empProfile } = response.data.data;
                    setEmployeeData1({
                        fullName: fullName,
                        personalEmailAddress: personalEmailAddress,
                        empProfile: empProfile
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching employee data:', error);
            });
    }, []);

    const fileInputRef = useRef(null);

    // Trigger file input click when the user clicks on the edit button
    const handleEditClick = () => {
        fileInputRef.current.click(); // Programmatically click the hidden input
    };

    // Handle file selection and update profile picture
    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];

        if (!selectedFile) {
            alert("No file selected.");
            return;
        }

        const formData = new FormData();
        formData.append("Empprofile", selectedFile); // Change 'file' to 'Empprofile'

        const token = localStorage.getItem('token'); // Get token from localStorage

        try {
            const response = await axios.post('https://api.ndtechland.com/api/EmployeeApi/EmployeeUpdateprofilepicture', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Pass the token here
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.succeeded) {
                alert(response.data.message);
                window.location.reload();
            } else {
                alert("Failed to update profile picture.");
            }
        } catch (error) {
            console.error("Error updating profile picture:", error);
            alert("An error occurred while updating the profile picture.");
        }
    };

    useEffect(() => {
        const fetchLeaveData = async () => {
            try {
                // Get token from localStorage
                const token = localStorage.getItem('token');

                if (!token) {
                    console.error("No token found in localStorage");
                    return;
                }

                // Log that API call is being made
                console.log("Fetching leave data...");

                // Make the API request with the token in the headers
                const response = await axios.get('https://api.ndtechland.com/api/LeaveStructure/GetLeaveType', {
                    headers: {
                        Authorization: `Bearer ${token}` // Add token to the Authorization header
                    }
                });

                // Check if the response is successful
                console.log("API Response: ", response);

                if (response.status === 200 && response.data.succeeded) {
                    const data = response.data.data;
                    setLeaveList(data.getLeaveList); // Set leave list
                    setLeaveTypeList(data.getLeaveTypeList); // Set leave type list
                    console.log("Data fetched successfully:", data);
                } else {
                    console.error("API call failed:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching leave data:", error);
            }
        };

        fetchLeaveData();
    }, []);

    const handleLeaveTypeChange = (event) => {
        setLeaveType(event.target.value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Retrieve the saved leave category ID from localStorage
        const savedLeaveCategory = localStorage.getItem('selectedLeaveCategory');

        // Construct the FormData object
        const formData = new FormData();
        formData.append('startLeaveId', leaveTypeForStartDate); // Leave Type for Start Date
        formData.append('endLeaveId', leaveTypeForEndDate || leaveTypeForStartDate); // Use same value for single leave
        formData.append('typeOfLeaveId', savedLeaveCategory || leaveCategory); // Get from localStorage or state
        formData.append('startDate', startDate); // Start date selected by user
        formData.append('endDate', endDate || startDate); // End date; same as start date for single leave
        formData.append('reason', reason); // Reason for leave

        try {
            // Get the token from localStorage for authorization
            const token = localStorage.getItem('token');

            // Make the API POST request to apply for leave
            const response = await axios.post(
                "https://api.ndtechland.com/api/LeaveStructure/EmployeeApplyLeave",
                formData, // Send the FormData object
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the headers
                        'Content-Type': 'multipart/form-data' // Set the content type to multipart/form-data
                    },
                }
            );

            // Handle the API response
            if (response.data.succeeded) {
                alert("Leave applied successfully!"); // Success message
            } else {
                alert(response.data.message || "Failed to apply leave"); // Error message
            }
        } catch (error) {
            console.error("Error applying leave:", error); // Log the error
            alert("An error occurred while applying leave."); // Alert the user about the error
        }
    };



    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState('2023-2024'); // Default year
    const [showAlert, setShowAlert] = useState(false);

    const handleMonthSelect = (month) => {
        setSelectedMonth(month);
        setShowAlert(true);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleDownloadSlip = async () => {
        if (selectedMonth && selectedYear) {
            const year = selectedYear.split('-')[1]; // Extracting the year part
            const token = localStorage.getItem('token'); // Retrieve the token from local storage

            try {
                const response = await axios.get(
                    `https://api.ndtechland.com/api/EmployeeApi/GetEmployeesalaryslip?month=${selectedMonth}&year=${year}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}` // Include the token in the request headers
                        }
                    }
                );

                if (response.data.succeeded) {
                    const { salarySlipPath, salarySlipName } = response.data.data;
                    // Open the PDF in a new tab
                    window.open(`https://api.ndtechland.com${salarySlipPath}`, '_blank');
                    // Optionally, you can store the salary slip info in state
                } else {
                    // Handle the case when fetching the salary slip fails
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching salary slip:', error);
            }
        } else {
            // Handle case where month or year is not selected
            alert("Please select both month and year.");
        }
    };


    const [showGraph, setShowGraph] = useState(false);

    // Function to open the popup
    const handleShowGraph = () => {
        setShowGraph(true);
    };

    // Function to close the popup
    const handleCloseGraph = () => {
        setShowGraph(false);
    };

    // Sample graph data (replace with actual data)
    const graphData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Attendance',
                data: [28, 25, 30, 27, 29, 26, 30, 31, 28, 27, 29, 30], // Replace with real data
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
            },
        ],
    };

    return (
        <>
            <Header />
            <section class=" dashboard-section bg-inner" data-sticky_parent>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="pro_sticky_info" data-sticky_column>
                                <div class="dashboard-sidebar">
                                    <div className="profile-top">
                                        <div className="profile-image">
                                            <img
                                                src={employeeData1.empProfile ? `https://api.ndtechland.com${employeeData1.empProfile}` : "../assets/images/avtar/1.jpg"}
                                                className="img-fluid blur-up lazyload"
                                                alt="Profile"
                                            />
                                            <a className="profile-edit" href="javascript:void(0)" onClick={handleEditClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                    strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit">
                                                    <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                                                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                                                </svg>
                                            </a>

                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }} // Hide the input
                                                onChange={handleFileChange}
                                                accept="image/*"
                                            />
                                        </div>

                                        <div className="profile-detail">
                                            <h5>{employeeData1.fullName}</h5>
                                            <h6>{employeeData1.personalEmailAddress}</h6>
                                        </div>
                                    </div>
                                    <div class="faq-tab">

                                        <ul className="nav nav-tabs" id="top-tab" role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'dashboard' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('dashboard')}
                                                    data-bs-toggle="tab"
                                                    href="#dashboard"
                                                >
                                                    <FontAwesomeIcon icon={faTachometerAlt} /> Dashboard
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('profile')}
                                                    data-bs-toggle="tab"
                                                    href="#profile"
                                                >
                                                    <FontAwesomeIcon icon={faUser} /> Profile
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'bankDetails' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('bankDetails')}
                                                    data-bs-toggle="tab"
                                                    href="#Bankdetails"
                                                >
                                                    <FontAwesomeIcon icon={faBank} /> Bank Details
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'offerLetter' ? 'active' : ''}`}
                                                    onClick={() => handleTabClick('offerLetter', documents.offerLetter)}
                                                    data-bs-toggle="tab"
                                                    href="#offerLetter"
                                                >
                                                    <FontAwesomeIcon icon={faFileAlt} /> Offer Letter
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'appointmentLetter' ? 'active' : ''}`}
                                                    onClick={() => handleTabClick('appointmentLetter', documents.appointmentLetter)}
                                                    data-bs-toggle="tab"
                                                    href="#appointmentLetter"
                                                >
                                                    <FontAwesomeIcon icon={faClipboardCheck} /> Appointment Letter
                                                </a>
                                            </li>


                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'taskManager' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('taskManager')}
                                                    data-bs-toggle="tab"
                                                    href="#taskManager"
                                                >
                                                    <FontAwesomeIcon icon={faTasks} /> Task Manager
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'events' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('events')}
                                                    data-bs-toggle="tab"
                                                    href="#events"
                                                >
                                                    <FontAwesomeIcon icon={faCalendarAlt} /> Events
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'attendance' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('attendance')}
                                                    data-bs-toggle="tab"
                                                    href="#attendance"
                                                >
                                                    <FontAwesomeIcon icon={faCalendarAlt} /> Attendance
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${activeTab === 'Changepassword' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('Changepassword')}
                                                    data-bs-toggle="tab"
                                                    href="#Changepassword"
                                                >
                                                    <FontAwesomeIcon icon={faLock} /> Change Password
                                                </a>
                                            </li>

                                        </ul>


                                        <Routes>

                                            <Route path="/profile" element={<Profile />} />
                                        </Routes>

                                        <Routes>

                                            <Route path="/Bankdetail" element={<Bankdetail />} />
                                        </Routes>

                                        <Routes>

                                            <Route path="/Taskmanager" element={<Taskmanager />} />
                                        </Routes>


                                        <Routes>

                                            <Route path="/Events" element={<Events />} />
                                        </Routes>


                                        <Routes>

                                            <Route path="/Attendance" element={<Attendance />} />
                                        </Routes>

                                        <Routes>

                                            <Route path="/Changepassword" element={<Changepassword />} />
                                        </Routes>

                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="col-lg-9">
                            <div class="product_img_scroll" data-sticky_column>
                                <div class="faq-content tab-content" id="top-tabContent">
                                    <div class="tab-pane fade show active" id="dashboard">
                                        <div class="dashboard-main">
                                            <div class="dashboard-intro">
                                                <h5>Welcome! <span>Your Dashboard</span></h5>
                                                <p>Complete your profile to access more features and functionality.</p>

                                                <div class="complete-profile container">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-md-6 mb-3">
                                                            <div class="complete-box">
                                                                <i class="far fa-check-square"></i>
                                                                <h6>Profile Section Completed</h6>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-4 col-md-6 mb-3">
                                                            <div class="complete-box">
                                                                <i class="far fa-check-square"></i>
                                                                <h6>Information Verified</h6>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-4 col-md-6 mb-3">
                                                            <div class="complete-box not-complete">
                                                                <i class="far fa-window-close"></i>
                                                                <h6>Additional Info Needed</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="counter-section">
                                                <div className="row">
                                                    <div className="col-xl-3 col-sm-12 mb-4">
                                                        <div className="counter-box text-center">
                                                            <i className="fas fa-calendar-check fa-3x"></i>
                                                            <h5>Total Leaves</h5>
                                                            <p>{dashboardData.leave}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-sm-12 mb-4">
                                                        <div className="counter-box text-center">
                                                            <i className="fas fa-calendar-minus fa-3x"></i>
                                                            <h5>Leave Left</h5>
                                                            <p>{dashboardData.leaveLeft}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-sm-12 mb-4">
                                                        <div className="counter-box text-center"
                                                            onClick={handleShowGraph}  // Handle click to show modal
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <i className="fas fa-user-check fa-3x"></i>
                                                            <h5>Total Attendance</h5>
                                                            <p>{dashboardData.totalAttendance}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-sm-12 mb-4">
                                                        <div
                                                            className="counter-box text-center" >
                                                            <i className="fas fa-user-clock fa-3x"></i>
                                                            <h5>Current Month Attendance</h5>
                                                            <p>{dashboardData.attendance}</p>
                                                        </div>
                                                    </div>

                                                    {/* Modal for showing the graph */}
                                                    <Modal show={showGraph} onHide={handleCloseGraph}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Attendance Graph</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            {/* Graph rendering inside the modal */}
                                                            <Line data={graphData} />
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={handleCloseGraph}>
                                                                Close
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            </div>


                                            <br />

                                            <div class="counter-section">
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-6">
                                                        <div class="counter-box" data-bs-toggle="modal" data-bs-target="#salarySlipModal" style={{ cursor: "pointer" }}>
                                                            <i class="fas fa-file-invoice fa-3x"></i>
                                                            <h5>Salary Slip</h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3 col-sm-6">
                                                        <div class="counter-box" data-bs-toggle="modal" data-bs-target="#applyLeaveModal" style={{ cursor: "pointer" }}>
                                                            <i class="fas fa-paper-plane fa-3x"></i>
                                                            <h5>Apply Leaves</h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="modal fade" id="applyLeaveModal" tabIndex="-1" aria-labelledby="applyLeaveModalLabel" aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="applyLeaveModalLabel">Apply Leave</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form id="leaveForm" onSubmit={handleFormSubmit}>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Apply for:</label>
                                                                    <div>
                                                                        <input
                                                                            type="radio"
                                                                            name="leaveApplicationType"
                                                                            value="single"
                                                                            id="singleLeave"
                                                                            checked={leaveType === "single"}
                                                                            onChange={handleLeaveTypeChange}
                                                                        />
                                                                        <label htmlFor="singleLeave">Single Leave</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            type="radio"
                                                                            name="leaveApplicationType"
                                                                            value="multiple"
                                                                            id="multipleLeave"
                                                                            checked={leaveType === "multiple"}
                                                                            onChange={handleLeaveTypeChange}
                                                                        />
                                                                        <label htmlFor="multipleLeave">Multiple Leaves</label>
                                                                    </div>
                                                                </div>

                                                                {leaveType === "single" && (
                                                                    <div className="leave-dates" id="singleLeaveDates">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="leaveCategory" className="form-label">
                                                                                Leave Category
                                                                            </label>
                                                                            <select
                                                                                className="form-select"
                                                                                id="leaveType"
                                                                                style={{ textAlign: "left" }}
                                                                                onChange={(e) => setLeaveTypeForStartDate(e.target.value)}
                                                                            >
                                                                                {leaveTypeList.length > 0 ? (
                                                                                    leaveTypeList.map((type) => (
                                                                                        <option key={type.id} value={type.id}>
                                                                                            {type.leavetype}{type.leaveValue}
                                                                                        </option>
                                                                                    ))
                                                                                ) : (
                                                                                    <option disabled>No leave type data available</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="leaveType" className="form-label">
                                                                                Leave Type
                                                                            </label>


                                                                            <select
                                                                                className="form-select"
                                                                                id="leaveCategory"
                                                                                style={{ textAlign: "left" }}
                                                                                onChange={(e) => setLeaveCategory(e.target.value)}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {leaveList.length > 0 ? (
                                                                                    leaveList.map((leave) => (
                                                                                        <option key={leave.id} value={leave.id}>
                                                                                            {leave.typeofleave}
                                                                                        </option>
                                                                                    ))
                                                                                ) : (
                                                                                    <option disabled>No leave data available</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="startDate" className="form-label">
                                                                                Leave Date
                                                                            </label>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="startDate"
                                                                                value={startDate}
                                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {leaveType === "multiple" && (
                                                                    <div className="leave-dates" id="multipleLeaveDates">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="leaveCategory" className="form-label">
                                                                                Leave Category
                                                                            </label>
                                                                            <select
                                                                                className="form-select"
                                                                                id="leaveType"
                                                                                style={{ textAlign: "left" }}
                                                                                onChange={(e) => setLeaveTypeForStartDate(e.target.value)}
                                                                            >
                                                                                {leaveTypeList.length > 0 ? (
                                                                                    leaveTypeList.map((type) => (
                                                                                        <option key={type.id} value={type.id}>
                                                                                            {type.leavetype}{type.leaveValue}

                                                                                            {localStorage.setItem('selectedLeaveCategory', type.id)}
                                                                                        </option>
                                                                                    ))
                                                                                ) : (
                                                                                    <option disabled>No leave type data available</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="leaveType" className="form-label">
                                                                                Leave Type for Start Date
                                                                            </label>
                                                                            <select
                                                                                className="form-select"
                                                                                id="leaveCategory"
                                                                                style={{ textAlign: "left" }}
                                                                                onChange={(e) => setLeaveTypeForStartDate(e.target.value)}
                                                                            >
                                                                                {leaveList.length > 0 ? (
                                                                                    leaveList.map((leave) => (
                                                                                        <option key={leave.id} value={leave.id}>
                                                                                            {leave.typeofleave}
                                                                                        </option>
                                                                                    ))
                                                                                ) : (
                                                                                    <option disabled>No leave data available</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="leaveType" className="form-label">
                                                                                Leave Type for End Date
                                                                            </label>
                                                                            <select
                                                                                className="form-select"
                                                                                id="leaveCategory"
                                                                                style={{ textAlign: "left" }}
                                                                                onChange={(e) => setLeaveTypeForEndDate(e.target.value)}
                                                                            >
                                                                                {leaveList.length > 0 ? (
                                                                                    leaveList.map((leave) => (
                                                                                        <option key={leave.id} value={leave.id}>
                                                                                            {leave.typeofleave}
                                                                                        </option>
                                                                                    ))
                                                                                ) : (
                                                                                    <option disabled>No leave data available</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="startDate" className="form-label">
                                                                                Leave Start Date
                                                                            </label>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="startDate"
                                                                                value={startDate}
                                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="endDate" className="form-label">
                                                                                Leave End Date
                                                                            </label>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="endDate"
                                                                                value={endDate}
                                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="mb-3">
                                                                    <label htmlFor="reason" className="form-label">
                                                                        Reason
                                                                    </label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        id="reason"
                                                                        rows="3"
                                                                        value={reason}
                                                                        onChange={(e) => setReason(e.target.value)}
                                                                    ></textarea>
                                                                </div>
                                                                <button type="submit" className="btn btn-primary">
                                                                    Submit
                                                                </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                            <div className="modal fade" id="salarySlipModal" tabIndex="-1" aria-labelledby="salarySlipModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="salarySlipModalLabel">Salary Slip</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="mb-3">
                                                                <label htmlFor="selectYear" className="form-label">Select Financial Year</label>
                                                                <select className="form-select" id="selectYear" onChange={handleYearChange} style={{ textAlign: "left" }}>
                                                                    <option value="2021-2022">2021-2022</option>
                                                                    <option value="2022-2023">2022-2023</option>
                                                                    <option value="2023-2024">2023-2024</option>
                                                                </select>
                                                            </div>

                                                            <div className="mb-3">
                                                                <label className="form-label">Select Month</label>
                                                                <div className="row text-center">
                                                                    {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((monthName, index) => (
                                                                        <div className="col-3 mb-2" key={index}>
                                                                            <button
                                                                                className="btn btn-outline-primary w-100"
                                                                                onClick={() => handleMonthSelect(index + 1)} // Month is 1-indexed
                                                                            >
                                                                                {monthName}
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                            {showAlert && (
                                                                <div className="alert alert-info" id="selectedMonthYear">
                                                                    You have selected <strong>{["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][selectedMonth - 1]}</strong> for the financial year <strong>{selectedYear}</strong>.
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                            <button type="button" className="btn btn-primary" onClick={handleDownloadSlip}>Download Slip</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="dashboard-info">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div id="chart">
                                                            <div class="detail-left">
                                                                <ul>
                                                                    <li><span class="completed"></span> Completed</li>
                                                                    <li><span class="upcoming"></span> Upcoming</li>
                                                                    <li><span class="cancelled"></span> Cancelled</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <div class="activity-box">
                                                            <h6>News & Updates</h6>
                                                            <ul class="activity-list">
                                                                <li>
                                                                    <i class="fas fa-user-edit"></i>
                                                                    Profile Updated
                                                                    <span class="activity-time">2 hours ago</span>
                                                                </li>
                                                                <li>
                                                                    <i class="fas fa-file-invoice-dollar"></i>
                                                                    Invoice Generated
                                                                    <span class="activity-time">1 day ago</span>
                                                                </li>
                                                                <li class="blue-line">
                                                                    <i class="fas fa-tasks"></i>
                                                                    Task Completed: "Annual Report"
                                                                    <span class="activity-time">3 days ago</span>
                                                                </li>
                                                                <li class="yellow-line">
                                                                    <i class="fas fa-bell"></i>
                                                                    Notification: "Meeting with Team"
                                                                    <span class="activity-time">5 days ago</span>
                                                                </li>
                                                                <li>
                                                                    <i class="fas fa-download"></i>
                                                                    Report Downloaded: "Sales Data"
                                                                    <span class="activity-time">1 week ago</span>
                                                                </li>
                                                                <li class="blue-line">
                                                                    <i class="fas fa-check-circle"></i>
                                                                    Completed Profile Verification
                                                                    <span class="activity-time">2 weeks ago</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="profile">
                                        <Profile />
                                    </div>

                                    <div className="tab-pane fade" id="Bankdetails">
                                        <Bankdetail />
                                    </div>

                                    <div class="tab-pane fade" id="taskManager">
                                        <Taskmanager />
                                    </div>

                                    <div class="tab-pane fade" id="events">
                                        <Events />
                                    </div>
                                    <div class="tab-pane fade" id="attendance">
                                        <Attendance />
                                    </div>
                                    <div class="tab-pane fade" id="Changepassword">
                                        <Changepassword />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Dashboards;
